<template>
    <div class="content-wrapper rq-container">
        <rq-banner
            icon="fas fa-exclamation-circle"
            variant="error"
            :message="errorMessage"
            :visible="hasErrors"
            @dismiss="errorMessage=''"
            dismissable
        />
        <div class="row">
            <div class="col col-6 form-group" v-show="!item.isNew">
                <label for="txt_file_scan_page_fileName">Filename</label>
                <input id="txt_file_scan_page_fileName" automation_id="txt_file_scan_page_fileName" v-model="item.fileScanPageFileName" type="text" class="form-control" disabled >
            </div>
            <div class="col col-6 form-group">
                <label for="drp_document_type">Document Type</label>
                <dx-select-box
                    :wrapper-attr="$utils.idAttrs('drp_document_type')"
                    :items="fileScanDocumentTypes"
                    value-expr="id"
                    display-expr="name"
                    v-model="itemDocumentType"
                    disabled
                />
            </div>
        </div>
        <div class="row">
            <div class="col col-12">
                <rq-action-form-group
                    label="Description"
                    label-for="drp_description_label"
                    required
                    show-action>
                    <template #action>
                        <b-form-checkbox
                            automation_id="chk_use_default_description"
                            :disabled="localSecurity.PredefinedDescriptions"
                            @change="onUseDefaultDescriptionChange"
                            v-model="item.useDefaultDescription">Use Default Description
                        </b-form-checkbox>
                    </template>
                    <template #default>
                        <dx-select-box v-if="item.useDefaultDescription || localSecurity.PredefinedDescriptions"
                            :wrapper-attr="$utils.idAttrs('drp_description')"
                            :items="fileScanDescriptions"
                            value-expr="id"
                            display-expr="name"
                            @value-changed="onPredefinedDescriptionChange"
                            v-model="itemFileScanDescriptionId"
                        />
                        <input v-if="!item.useDefaultDescription"
                            id="txt_description"
                            automation_id="txt_description"
                            v-model="item.description"
                            type="text"
                            class="form-control"
                            placeholder="Description"
                            @change="onDescriptionChange" >

                    </template>
                </rq-action-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col col-12 form-group form-required">
                <label for="drp_file_scan_category_id">Category</label>
                <dx-select-box
                    :wrapper-attr="$utils.idAttrs('drp_file_scan_category_id')"
                    :items="fileScanCategories"
                    value-expr="id"
                    display-expr="name"
                    @value-changed="onCategoryChange"
                    v-model="item.fileScanCategoryID"
                />
            </div>
        </div>
        <div class="row">
            <div class="col col-12 form-group">
                <label for="tb_tags">Tags</label>
                <dx-tag-box
                    automation_id="tb_tags"
                    ref="tb_tags"
                    class="form-control"
                    :data-source="documentTags"
                    display-expr="name"
                    value-expr="id"
                    :search-enabled="true"
                    :show-selection-controls="true"
                    :show-clear-button="true"
                    :max-displayed-tags="3"
                    :show-drop-down-button="true"
                    apply-value-mode="useButtons"
                    v-model="item.tagIDs"
                    @valueChanged="onTagChange"
                />
            </div>
        </div>
        <div class="row" v-show="item.requiresAttention">
            <div class="col col-12 form-group form-required">
                <label for="drp_file_scan_duplicate_action">Action</label>
                <dx-select-box
                    :wrapper-attr="$utils.idAttrs('drp_file_scan_duplicate_action')"
                    :items="fileScanDocumentDuplicateActions"
                    value-expr="id"
                    display-expr="name"
                    v-model="item.fileScanDocumentDuplicateAction"
                    @value-changed="onActionChange"
                />
            </div>
        </div>
        <div class="row">
            <div class="col col-6 form-group">
                <label for="txt_published_by">Author</label>
                <input id="txt_published_by" automation_id="txt_published_by" v-model="item.publishedByDisplay" type="text" class="form-control" disabled >
            </div>
            <div class="col col-6 form-group">
                <label for="txt_date_modified">Last Modified On</label>
                <input id="txt_date_modified" automation_id="txt_description" :value="modifiedDate" type="text" class="form-control" disabled >
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters } from "vuex";
    import DxTagBox from "devextreme-vue/tag-box";
    import { SystemLookupItem } from "@/shared/models/models";
    import { DocumentFileType, FileScanDocumentType }  from "../../documents/enums";
    import { FileScanDocumentDuplicateAction }  from "../enums";
    import DateTimeHelper from "@/shared/utilities/DateTimeHelper";

    export default {
        name: 'FileScanDocumentProperties',
        components: { DxTagBox },
        props: {
            item: {type: Object, required: true ,default: null}
        },
        data() {
            return {
                hadError: false,
                hasErrors: false,
                originalDescription: "",
                errorMessage: "",
                fileScanDocumentDuplicateActions: [],
                fileScanDescriptions: [],
            }
        },
        computed: {
            ...mapGetters([
                "lookupHelpers",
                "lookupItems"
            ]),
            ...mapState({
                orderRegionID: state => state.orders?.order?.regionID
            }),
            hasDescriptionChanged() { return !_.isEqual(this.originalDescription, this.item.description); },
            documentTags() { return this.lookupHelpers.getLookupItems(this.lookupItems.DOCUMENT_TAGS); },
            fileScanCategories() { return this.lookupHelpers.getLookupItems(this.lookupItems.FILE_SCAN_CATEGORIES, this.orderRegionID); },
            fileScanDocumentTypes() { return FileScanDocumentType.lookupItems; },
            localSecurity(){
                return this.securitySettings.findValues(["CanReplaceDocuments", "PredefinedDescriptions"]);
            },
            modifiedDate() { return DateTimeHelper.toLocalTimeString(this.item.dateModified); },
            itemDocumentType: {
                get() { return this.item?.documentType || this.fileScanDocumentTypes[0].id; },
                set(val) { this.item.documentType = val; }
            },
            itemFileScanDescriptionId: {
                get() { return this.item?.fileScanDescriptionID || this.fileScanDescriptions[0]?.id; },
                set(val) { this.item.fileScanDescriptionID = val; }
            }
        },
        created(){
            this.init();
        },
        methods: {
            init() {
                this.originalDescription = this.item.description;
                if (this.localSecurity.CanReplaceDocuments) {
                    this.fileScanDocumentDuplicateActions = _.filter(FileScanDocumentDuplicateAction.lookupItems, i => i.id > 0);
                } else {
                    this.fileScanDocumentDuplicateActions = _.filter(FileScanDocumentDuplicateAction.lookupItems, i => i.id != FileScanDocumentDuplicateAction.Ignore && i.id != FileScanDocumentDuplicateAction.ReplaceExisting);
                }
                this.fileScanDescriptions = this.lookupHelpers.getLookupItems(this.lookupItems.FILE_SCAN_DESCRIPTIONS, this.orderRegionID);
                this.fileScanDescriptions.push(new SystemLookupItem({id: 0, name: this.item.standardDescription}));

                if (this.item.requiresAttention) {
                    this.errorMessage = `Document with file type [${this.item.fileTypeDisplay}] and Description [${this.item.description}] already exists.  Please update the description or choose the appropriate Action.`;
                    this.hasErrors = true;
                    this.hadError = true;
                    this.setModalOk(false);
                }
            },
            onActionChange(e) {
                if(_.isNil(e.event)) return;
                if(!_.isNumber(e.value)) return;
                if(e.value === FileScanDocumentDuplicateAction.CreateNew) {
                    this.item.description = `${this.item.description}_${DateTimeHelper.now("yyyy-MM-dd hh:mm:ss a")}`;
                    this.item.useDefaultDescription = false;
                }
                if(e.value === FileScanDocumentDuplicateAction.ReplaceExisting) {
                    this.item.description = `${this.originalDescription}`;
                }
                this.setModalOk();
                this.errorMessage = "";
            },
            onCategoryChange(e) {
                if(_.isNil(e.event)) return;
                this.item.fileScanCategoryDisplay = this.lookupHelpers.getLookupItemName(this.lookupItems.FILE_SCAN_CATEGORIES, e.value);
            },
            onDescriptionChange(e) {
                this.hasErrors = this.hasErrors && !this.hasDescriptionChanged;
                if(!this.hasErrors && this.item.requiresAttention) {
                    this.errorMessage = "";
                    this.item.fileScanDocumentDuplicateAction = FileScanDocumentDuplicateAction.CreateNew;
                    this.item.requiresAttention = false;
                    this.setModalOk();
                }
            },
            onUseDefaultDescriptionChange(checked) {
                if(!checked) {
                    this.item.description = this.originalDescription;
                    if (this.hadError) {
                        this.errorMessage = `Document with file type [${this.item.fileTypeDisplay}] and Description [${this.item.description}] already exists.  Please change the description or choose the appropriate Action.`;
                        this.hasErrors = true;
                        this.setModalOk(false);
                    }
                }
            },
            onPredefinedDescriptionChange(e) {
                if(_.isNil(e.event)) return;
                if(_.isNil(e.value)) {
                    this.setModalOk(false);
                    return;
                }
                if(this.item.useDefaultDescription) {
                    let item = _.find(this.fileScanDescriptions, ["id", e.value]);
                    this.item.description = item.name;
                    this.setModalOk();
                }
                this.hasErrors = this.hasErrors && !this.hasDescriptionChanged;
                if(!this.hasErrors && this.item.requiresAttention) {
                    this.errorMessage = "";
                    this.item.fileScanDocumentDuplicateAction = FileScanDocumentDuplicateAction.CreateNew;
                    this.item.requiresAttention = false;
                    this.setModalOk();
                }
            },
            onTagChange(e) {
                this.item.tagNames = _.join(_.map(e.value, id => _.find(this.documentTags, { id }).name), ", ") || "";
            },
            setModalOk(enable=true) {
                this.$emit(enable ? "enable-ok" : "disable-ok");
            },
        }
    }
</script>