<template>
    <div class="document-pane-wrapper">
       <rq-page-section
            :title="sectionTitle"
            headerSize="md"
            :show-header="itemCount > 0"
            borderless
            flex-full
            flex-body>
            <template #header-actions>
                <ul v-if="showEditButton" class="nav">
                    <li class="nav-item"
                        :title="editDisabled ? 'Access Restricted' : ''"
                        v-rq-tooltip.hover.top>
                        <button
                            automation_id="btn_edit_document"
                            type="button"
                            class="btn btn-theme"
                            :disabled="editDisabled"
                            @click="openEditor">Edit
                        </button>
                    </li>
                </ul>
            </template>
            <div class="document-pane">
                <rq-no-data
                    v-if="showNoDataMessage"
                    size="lg">
                    <template #message-content>
                        <span v-if="itemCount > 1">Multiple files selected, <br /> preview not available.</span>
                        <span v-else>Preview not available.</span>
                    </template>
                </rq-no-data>
                <rq-pdf-viewer
                    v-if="!editDialogOpen"
                    v-show="showPdfViewer"
                    ref="pdfViewer"
                    :exclude-toolbar-items="hiddenPdfToolbarItems"
                    :features="features"
                    @document-loaded="onPdfDocumentLoaded"
                    @printed="onPdfViewerPrinted"
                    read-only
                />
                <rq-image-viewer
                    v-if="showImageViewer"
                    :data-url="documentContent"
                    :download-file-name="downloadFileName"
                    id="rqImageViewer"
                    ref="rqImageViewer"
                />
                <rq-email-viewer
                    v-if="showMsgFile"
                    :email="email"
                />
            </div>
        </rq-page-section>
    </div>
</template>

<script>
    import { ref, computed } from "vue";
    import { useStore } from "vuex";
    import { useLicenseStore } from "@/store/modules/license";
    import { useSecuritySettings } from "@/shared/composables/useSecuritySettings";

    import {
        RqPdfViewer,
        RqImageViewer,
        RqEmailViewer,
    } from "@/shared/components/rq/";

    import { FileScanDocumentAction }  from "../enums";
    import { DocumentFileType }  from "@documents/enums";
    import EditPdfDialog from "@/shared/components/rq/dialogs/EditPdfDialog";
    import DocumentEditorDialog from "@/shared/components/rq/dialogs/DocumentEditorDialog";
    export default {
        name: 'FileScanDocumentPane',
        components: { RqPdfViewer, RqImageViewer, RqEmailViewer },
        props: {
            items: {type: Array, required: true, default: () => []},
            action: {type: Number, default: 0},
            saveAsFileType: {type: Number, default: 0},
            showPreview: {type: Boolean, required: true, default: true}
        },
        emits: ["update:action", "update-item", "pdf-loaded", "pdf-printed", "reload-data"],
        setup(props) {
            const store = useStore(); //vuex store
            const licenseStore = useLicenseStore();

            const itemKey = ref("fileScanDocumentID");
            const fileScanDocumentID = ref(0);
            const fileName = ref("");
            const fileType = ref(null);
            const downloadFileName = ref("");
            const description = ref("");
            const documentContent = ref("");
            const email = ref(null);
            const validationErrors = ref([]);
            const editDialogOpen = ref(false);

            const { localSecurity } = useSecuritySettings([
                "CanEditDocuments",
                "CanEmailDocuments",
                "CanOpenDocuments",
                "CanPrintDocuments",
                "CanMergeDocuments",
                "FileScanAllowEditDocuments"
            ]);

            const isFileLocked = computed(() => store.state.orders.orderSummary.isLocked);
            const isDocx = computed(() => fileType.value === DocumentFileType.DOCX);
            const isPdf = computed(() => fileType.value === DocumentFileType.PDF);
            const isMsg = computed(() => fileType.value === DocumentFileType.MSG || fileType.value == DocumentFileType.EML);

            const itemCount = computed(() => props.items?.length || 0);

            const canEdit = computed(() => fileType.value === DocumentFileType.DOCX);
            const canViewNative = computed(() => _.includes([
                DocumentFileType.GIF,
                DocumentFileType.BMP,
                DocumentFileType.JPG,
                DocumentFileType.PNG
            ], fileType.value));
            const canView = computed(() => _.includes([
                DocumentFileType.DOC,
                DocumentFileType.DOCX,
                DocumentFileType.DOCM,
                DocumentFileType.XML,
                DocumentFileType.TXT,
                DocumentFileType.RTF,
                DocumentFileType.HTML,
                DocumentFileType.HTM,
                DocumentFileType.XML,
                DocumentFileType.PDF,
                DocumentFileType.PDF_COMBINED,
                DocumentFileType.MSG,
                DocumentFileType.EML
            ], fileType.value));

            const hasContent = computed(() => props.items?.[0]?.content?.length > 0);
            const hasContentLoaded = computed(() => !_.isEmpty(documentContent.value));

            const showMsgFile = computed(() => isMsg.value && !_.isEmpty(email.value));
            const showPdfViewer = computed(() => props.showPreview
                && !isMsg.value
                && (
                    (itemCount.value === 1 && props.action == FileScanDocumentAction.Preview && (canView.value || canEdit.value) && hasContentLoaded.value) ||
                    (itemCount.value >= 1  && props.action == FileScanDocumentAction.Print && canView.value && hasContentLoaded.value)
                )
            );
            const showImageViewer = computed(() => props.showPreview
                && itemCount.value == 1
                && props.action == FileScanDocumentAction.Preview
                && canViewNative.value
                && hasContentLoaded.value
                && !isMsg.value
            );
            const showNoDataMessage = computed(() => !showPdfViewer.value
                && !showImageViewer.value
                && !showMsgFile.value);

            const showEditButton = computed(() => itemCount.value == 1
                && canView.value
                && (isDocx.value || isPdf.value)
            );

            const sectionTitle = computed(() => showMsgFile.value ? "Email Preview" : "Document Preview");

            const features = computed(() => ({
                annotations: licenseStore.features.pdfAnnotations,
                bookmarks: licenseStore.features.pdfBookmarks,
                editing: licenseStore.features.pdfEditing,
                formCreator: licenseStore.features.pdfFormCreator,
                redaction: licenseStore.features.pdfRedaction,
                standard: true,
            }));

            const hiddenPdfToolbarItems = computed(() => {
                if(!isFileLocked.value) return [];
                let printRelated = localSecurity.value.CanPrintDocuments ? [] : ["print", "export-pdf"];
                //PDF EDITING FEATURES PENDING IMPLEMENTATION
                let editRelated = []; //this.localSecurity.CanEditDocuments ? [] : [];

                return _.uniq([
                    ...printRelated,
                    ...editRelated
                ]);
            });

            const pdfEditDisabled = computed(() => {
                let advancedFeatures = _.pick(features.value, ["annotations", "bookmarks", "editing", "formCreator", "redaction"]);
                return !_.some(advancedFeatures);
            });

            const editDisabled = computed(() => isFileLocked.value
                || _.some(props.items, { isReadOnly: true })
                || !localSecurity.value.FileScanAllowEditDocuments
                || !localSecurity.value.CanOpenDocuments
                || (isPdf.value && pdfEditDisabled.value));

            return {
                //data
                itemKey,
                fileScanDocumentID,
                fileName,
                fileType,
                downloadFileName,
                description,
                documentContent,
                email,
                validationErrors,
                editDialogOpen,

                //computed
                sectionTitle,
                localSecurity,
                isFileLocked,
                canEdit,
                canViewNative,
                canView,
                itemCount,
                hasContent,
                hasContentLoaded,
                isDocx,
                isPdf,
                isMsg,
                showMsgFile,
                showPdfViewer,
                showImageViewer,
                showEditButton,
                showNoDataMessage,
                pdfEditDisabled,
                editDisabled,
                features,
                hiddenPdfToolbarItems,
            };
        },
        watch: {
            items: {
                handler(newValue, oldValue) {
                    if (!this.showPreview) {this.documentContent = "";return;}
                    if(!_.isEqual(newValue, oldValue) && newValue.length == 1) {
                        this.$rqBusy.clearWaits();
                        this.setData();
                    }
                    if(newValue.length !== 1) {
                        this.documentContent = "";
                        this.email = null;
                    }
                },
                deep: true,
                immediate: true
            },
            action(newValue, oldValue) {
                if (_.isEqual(newValue, oldValue) || (!this.showPreview && newValue == FileScanDocumentAction.Preview)) return;
                this.$nextTick(()=>{
                    this.performAction();
                });
            },
            showPreview(newValue) {
                if (!newValue || this.items.length !== 1) return;
                this.$rqBusy.clearWaits();
                this.setData();
            }
        },
        methods: {
            downloadContent() {
                const self = this;
                let ids = _.map(self.items, self.itemKey);
                let apiPromise = self.$api.FileScanApi.getFileScanDocumentData(self.action, self.saveAsFileType, ids);
                self.$rqBusy.wait(apiPromise)
                    .then(files => {
                        _.each(files, file => {
                            self.saveToClient(file);
                        });
                    })
                    .catch(error => {
                        self.$toast.error({ message: `Error loading Documents.` });
                    })
                    .finally(() => {
                        self.setAction(FileScanDocumentAction.Preview);
                    });
            },

            fetchData(fileScanDocumentID) {
                const self = this;
                let apiPromise = self.$api.FileScanApi.getFileScanDocument(fileScanDocumentID, self.action);
                return self.$rqBusy.wait(apiPromise);
            },

            getContent() {
                const self = this;
                self.fetchData(self.fileScanDocumentID)
                    .then((result) => {
                        self.setContent(result);
                    })
                    .catch(error => {
                        if (self.itemCount == 1) {
                            self.$toast.error({ message: `Error retrieving ${self.description} from storage.` });
                        }
                        self.setAction(FileScanDocumentAction.Preview);
                    })
                    .finally(() => {
                        self.loadContent();
                    });
            },

            loadContent() {
                const self = this;
                if(!self.showPdfViewer) return;
                self.invokePdfViewerMethod("load", { content: self.documentContent });
            },

            onPdfDocumentLoaded() {
                if (this.action == FileScanDocumentAction.Print) {
                    this.invokePdfViewerMethod("print");
                }
                this.$emit("pdf-loaded", true);
            },

            onPdfViewerPrinted() {
                this.$emit("pdf-printed", true);
            },

            openEditor() {
                const self = this;
                self.setAction(FileScanDocumentAction.Edit);
            },

            performAction() {
                const self = this;
                switch (self.action) {
                    case FileScanDocumentAction.Preview:
                    case FileScanDocumentAction.Edit:
                        self.setData();
                        return;
                    case FileScanDocumentAction.Download:
                    case FileScanDocumentAction.DownloadZip:
                        self.downloadContent();
                        return;
                    case FileScanDocumentAction.Print:
                        if (self.itemCount == 1 && self.hasContentLoaded) {
                            self.invokePdfViewerMethod("print");
                        } else {
                            self.printContent();
                        }
                        return;
                }
            },

            openEditorDialog(documentContent = null){
                const self = this;
                if(self.isPdf){
                    self.openPdfEditor(documentContent);
                }
                else if(self.isDocx){
                    self.openDocEditor(documentContent);
                }
            },

            async openPdfEditor(documentContent = null){
                const self = this;

                self.editDialogOpen = true;

                await self.$nextTick();

                documentContent = documentContent ?? self.documentContent;

                self.$dialog.open({
                    title: `Edit Pdf`,
                    height:"90%",
                    width: "90%",
                    component: EditPdfDialog,
                    props: {
                        content: documentContent
                     },
                    okTitle: "Save",
                    onOk: async e => {
                        let content = await e.component.saveContent();
                        var result = await self.saveContent(content);
                        self.$emit('reload-data');
                        return result; 
                    },
                    onCancel(e){
                        self.closeEditorDialog();
                        return true;
                    }
                });
            },

            async openDocEditor(documentContent = null) {
                const self = this;

                self.editDialogOpen = true;

                await self.$nextTick();

                documentContent = documentContent ?? self.documentContent;

                self.$dialog.open({
                    title: `Edit Document`,
                    height:"95%",
                    width: "95%",
                    component: DocumentEditorDialog,
                    props: {
                        documentName: self.downloadFileName,
                        documentContent,
                        fileType: self.fileType
                     },
                    okTitle: "Save",
                    onOk: async e => {
                        if(!e.component?.isDirty()) return true;
                        let contentResult = await e.component.getContent({ docx: true, pdf: true, showBusy: false });
                        let pdfDataUrl = `data:application/pdf;base64,${contentResult.pdf}`;
                        var result = await self.saveContent(contentResult.docx, pdfDataUrl);
                        self.$emit('reload-data');
                        return result;
                    },
                    onCancel(e){
                        self.setContent("");

                        self.closeEditorDialog();
                        return true;
                    }
                });
            },

            async saveContent(content, pdfContent=null) {
                const self = this;
                try {
                    let savePromise = self.$api.FileScanApi.saveFileScanDocument({ ...self.items[0], fileBytes: content, content: null });
                    await self.$rqBusy.wait(savePromise);
                    self.setContent(pdfContent || content);
                    await self.$nextTick();
                    await self.closeEditorDialog();
                    self.$toast.success(`${self.description} has been saved.`);
                    return true;
                }
                catch(error) {
                    console.error(error);
                    self.$toast.error("Error Saving Document.");
                }
            },

            async closeEditorDialog() {
                const self = this;
                self.editDialogOpen = false;
                await self.$nextTick();
                self.setAction(FileScanDocumentAction.Preview);
            },

            printContent() {
                const self = this;
                self.documentContent = "";
                let ids = _.map(self.items, self.itemKey);
                let apiPromise = self.$api.FileScanApi.getFileScanDocumentData(self.action, 0, ids);
                self.$rqBusy.wait(apiPromise)
                    .then(files => {
                        self.fileType = DocumentFileType.PDF_COMBINED;
                        self.documentContent = files[0].content;
                    })
                    .catch(error => {
                        console.log(error);
                        self.setAction(FileScanDocumentAction.Preview);
                        self.$toast.error({ message: `Error loading Documents.` });
                    })
                    .finally(() => {
                        self.invokePdfViewerMethod("load", { fileName: self.downloadFileName, content: self.documentContent });
                    });
            },

            saveToClient(file) {
                const self = this;
                var blob = _.base64ToBlob(file.content, file.contentType);
                if (window.navigator.msSaveOrOpenBlob) // IE10+
                    window.navigator.msSaveOrOpenBlob(blob, file.commonFileName);
                else { // Others
                    var a = document.createElement("a")
                    var url = URL.createObjectURL(blob);
                    a.href = url;
                    a.download = file.commonFileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
            },

            setAction(action) {
                this.$emit('update:action', action);
            },

            setContent(item) {
                const self = this;
                let doc = _.clone(self.items[0]);
                let docContent = null;

                if(this.fileType === DocumentFileType.MSG || this.fileType === DocumentFileType.EML){
                    self.email = item.email;
                }
                else
                {
                    if(_.isString(item))
                        docContent = item;
                    else
                        docContent = item.content;

                    doc.content = self.documentContent = docContent;

                     if(self.action === FileScanDocumentAction.Edit  && (!self.editDialogOpen)){
                        self.openEditorDialog(docContent);
                    }

                    self.$emit('update-item', doc);
                }
            },

            setData: _.debounce(function (e) {
                const self = this;
                self.documentContent = "";
                if (self.items[0].fileScanDocumentID === 0 || self.itemCount > 1) return;
                self.fileScanDocumentID = self.items[0].fileScanDocumentID;
                self.description = self.items[0].description;
                self.fileName = self.items[0].fileScanPageFileName;
                self.fileScanDocumentID = self.items[0].fileScanDocumentID;
                self.fileType = self.items[0].fileType;
                self.downloadFileName = self.items[0].commonFileName;
                if (self.hasContent) {
                    self.documentContent = (self.action === FileScanDocumentAction.Edit && self.fileType !== DocumentFileType.PDF && _.startsWith(self.items[0].content, "data:application/pdf"))
                                        ? "" : self.items[0].content;

                    if(self.action === FileScanDocumentAction.Edit  && !self.editDialogOpen && self.hasContentLoaded){
                        self.openEditorDialog(self.documentContent);
                    }
                }
                if ((self.canView || self.canViewNative || self.canEdit) && self.hasContentLoaded) {
                    self.loadContent();
                } else if (self.canView || self.canViewNative || self.canEdit) {
                    self.getContent();
                }
            }, 500, {trailing: false, leading: true}),

            setItem(item) {
                const self = this;
                let doc = _.clone(self.items[0]);
                _.assign(doc, item);
                self.$emit('update-item', doc);
            },

            invokePdfViewerMethod(method, ...params) {
                return _.invoke(this, `$refs.pdfViewer.${method}`, ...params);
            },
        }
    }
</script>